<!--商品计划日结-->
<template>
    <div class="DailySettle" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="92px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组">
                            <el-select
                                v-model="form.deptGroupCode"
                                @change="handleChangeGroup"
                                filterable
                                :loading="loadingDeptGroupFlag"
                            >
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="group in meta.groups"
                                    :value="group.code"
                                    :key="group.code"
                                    :label="group.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode" @change="tableData = []" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.deptCodes"
                                    :label="dept.name"
                                    :key="dept.code"
                                    :value="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="审核状态">
                            <el-select placeholder="审核状态" v-model="form.reviewStatus">
                                <el-option label="请选择" value="" />
                                <el-option label="待审核" value="0" />
                                <el-option label="已审核" value="2" />
                                <el-option label="已拒绝" value="-1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="(开)结算日期">
                            <el-date-picker
                                v-model="form.startTime"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="(结)结算日期">
                            <el-date-picker
                                v-model="form.endTime"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="searchHandleQuery"
                size="small"
                v-if="hasPrivilege('menu.plan.goods.dailySettle.open')"
                >查询
            </el-button>
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.plan.goods.dailySettle.create')"
                >新建
            </el-button>
            <el-button
                type="primary"
                @click="handleAudit"
                size="small"
                v-if="hasPrivilege('menu.plan.goods.dailySettle.review')"
                >审核
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.plan.goods.dailySettle.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <ef-review-biz ref="review" baseUrl="/merchandising/settle" @completed="handleQuery" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                ref="table"
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />

                <el-table-column label="编码" width="180" prop="code" v-if="showColumn('code')" />
                <el-table-column
                    label="机构组名称"
                    width="240"
                    prop="deptGroupName"
                    v-if="showColumn('deptGroupName')"
                />
                <el-table-column label="机构名称" width="160" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column label="收银员" width="120" prop="cashierName" v-if="showColumn('cashierName')" />
                <el-table-column
                    label="审核状态"
                    width="120"
                    prop="reviewStatus"
                    v-if="showColumn('reviewStatus')"
                    key="reviewStatus"
                >
                    <template slot-scope="scope"
                        ><span>{{ scope.row.reviewStatus | reviewStatus }}</span></template
                    >
                </el-table-column>
                <el-table-column label="结算日期" width="120" prop="settleDate" v-if="showColumn('settleDate')" />
                <el-table-column label="创建人" width="120" prop="creator" v-if="showColumn('creator')" />
                <el-table-column label="创建时间" width="200" prop="createTime" v-if="showColumn('createTime')" />

                <el-table-column
                    label="操作"
                    width="145"
                    header-align="center"
                    v-if="showColumn('operate')"
                    key="operate"
                    prop="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.plan.goods.dailySettle.open')"
                            >查看
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            v-if="hasPrivilege('menu.plan.goods.dailySettle.delete')"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import UrlUtils from '../../../../js/UrlUtils';
import Util from '../../../../js/Util';
import EfReviewBiz from 'components/EfReviewBiz';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
export default {
    name: 'GoodsPlanDailySettle',
    components: { EfReviewBiz, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                deptCode: '',
                reviewStatus: '',
                deptGroupCode: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                page: 1,
                limit: Util.Limit,
            },
            auditCodes: [],
            audit: {
                auditDate: '',
                advice: '1',
                remark: '',
            },
            meta: {
                groups: [],
                deptCodes: [],
            },
            loadingDeptGroupFlag: true,
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            url: {
                page: '/merchandising/settle/page',
                queryGroups: '/system/deptGroup/staffManagedAllDeptGroups',
                queryDeptByGroup: '/system/dept/deptTree',
                delete: '/merchandising/settle/delete',
                review: '/merchandising/settle/review/',
            },
        };
    },
    filters: {
        reviewStatus(reviewStatus) {
            if (typeof reviewStatus == 'undefined') {
                return '';
            }
            if (reviewStatus == '0') {
                return '待审核';
            }
            if (reviewStatus == '2') {
                return '已审核';
            }
            if (reviewStatus == '-1') {
                return '已拒绝';
            }
        },
    },
    mounted() {
        UrlUtils.DeptGroup(this, (rst) => {
            this.meta.groups = rst || [];
            if (this.meta.groups.length == 1) {
                this.form.deptGroupCode = this.meta.groups[0].code;
                this.handleChangeGroup(this.form.deptGroupCode);
            }
            this.loadingDeptGroupFlag = false;
        });
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleAudit() {
            this.$refs.review.open(this.$refs.table.selection);
        },
        handleChangeGroup(value) {
            const _this = this;
            this.form.deptCode = '';
            if (_this.meta.deptCodes[value]) {
                return _this.meta.deptCodes[value];
            }
            UrlUtils.QueryRemote(this, this.url.queryDeptByGroup + '?deptGroupCode=' + value, (rst) => {
                _this.meta.deptCodes = rst || [];
                if (_this.meta.deptCodes.length == 1) {
                    _this.form.deptCode = _this.meta.deptCodes[0].code;
                }
            });
        },
        handleDelete(row) {
            if (row.reviewStatus != 0) {
                this.$message.error('非待审核单据不能删除');
            } else {
                UrlUtils.DeleteRemote(this, this.url.delete, row.code);
            }
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleCreate() {
            Util.nameJump(this, 'menu.plan.goods.dailySettle.create', ['财务管理', '商品计划日结', '新建商品计划日结']);
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((d) => d.code);
            if (Util.objectValueAllEmpty(this.form) && codes.length === 0) {
                this.$confirm('无搜索条件时,将仅导出最近三个月的数据', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.form.startTime = Util.formatDateOfLastThreeMonth();
                    this.form.endTime = Util.nowFormatDate();
                    UrlUtils.Export(this, '商品计划日结', '/merchandising/settle/export', this.form, codes);
                });
            } else {
                UrlUtils.Export(this, '商品计划日结', '/merchandising/settle/export', this.form, codes);
            }
        },
        rowDetail(row) {
            Util.nameJump(
                this,
                'menu.plan.goods.dailySettle.detail',
                ['财务管理', '商品计划日结', '商品计划日结详情'],
                {
                    form: row,
                    code: row.code,
                }
            );
        },
    },
};
</script>

<style scoped>
.DailySettle .el-form-item {
    margin-bottom: 0;
}
</style>
